import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import Link from 'gatsby-link'
import { Title, Section, Box, Span } from "../../components/Core";
import imgC1 from "../../assets/image/jpeg/vampire-facial.jpg";
import imgC2 from "../../assets/image/jpeg/mesotherapy.jpg";
import imgC3 from "../../assets/image/jpeg/hydra.jpg";
import imgC4 from "../../assets/image/jpeg/micro.jpg";
import imgC5 from "../../assets/image/jpeg/dermal-hero-pop.jpg";
import imgC6 from "../../assets/image/jpeg/tear.jpg";
import imgC7 from "../../assets/image/jpeg/gsmile.jpg";
import imgC8 from "../../assets/image/jpeg/chinjaw.jpg";
import imgC9 from "../../assets/image/jpeg/eyelift.jpg";
import { device, breakpoints } from "../../utils";
import styled from 'styled-components';


const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;



const ContentCard = ({
  color = "primary",
  className,
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box
  bg="#fffbf6"
  border="1px solid"
  borderColor="border"
  p="20px"
  borderRadius={10}
  className={`d-flex align-items-center ${className}`}
  {...rest}
  css={`
    transition: all 0.3s ease-out;
    &:hover {
      box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow};`};
    }
  `}
>
  <Box
    size={55}
    minWidth={55}
    borderRadius="50%"
    color={color}
  
    fontSize="14px"
    className="d-flex justify-content-center align-items-center"
    css={`
      background: url(${iconName})  ;
      background-size:cover;
    `}
    mr={3}
  >
    
  </Box>

  <Title variant="card" fontSize="10px" mb={0}>
    {title}
  </Title>
</Box>
);

const SkinTreatments = () => (
  <>
    <Section py={4} className="pb-md-5 mb pt-md-5" bg="#fff">
      <Container  className="pb-md-5 mb pt-md-5">
        <Row className="justify-content-center">
          <Col lg="9">
            <Box className="text-center" mb={[4, 5]}  data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
            >
             
            </Box>
          </Col>
        </Row>
        <Row className="justify-content-center">

        <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
            <Link to="/advanced-facial-treatments-london"><ContentCard
              title="Advanced Facials"
              color="primary"
              iconName={imgC5}
            /></Link>
          </Col>
    
          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
            <Link to="/prp-face-lift"><ContentCard
              title="PRP Face Lift"
              color="primary"
              iconName={imgC1}
            /></Link>
          </Col>

          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
            <Link to="/microneedling"><ContentCard
              title="Microneedling"
              color="primary"
              iconName={imgC4}
            /></Link>
          </Col>

          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
            <Link to="/hydrafacial"><ContentCard
              title="HydraFacial"
              color="primary"
              iconName={imgC3}
            /></Link>
          </Col>
          <Col
            lg="4"
            md="6"
            xs="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
            <Link to="/mesotherapy"><ContentCard
              title="Mesotherapy"
              color="primary"
              iconName={imgC2}
            /></Link>
          </Col>
         
        </Row>
      </Container>
    </Section>
  </>
);

export default SkinTreatments;
